<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Reassessment Questionnaires ({{ listMeta.total }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn
        color="primary"
        class="mb-2"
        @click="addQuestionnaireDialog = true"
      >
        <v-icon left> {{ icons.add }} </v-icon>Add Questionnaire
      </v-btn>
    </v-app-bar>

    <div class="mt-12 mb-6">
      <v-row>
        <v-col cols="6" md="3">
          <v-text-field
            outlined
            clearable
            hide-details
            label="Search"
            @input="doFilter"
            placeholder="Search by Name or Label"
            v-model="search"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>

    <v-data-table
      :items="reassessmentQuestionnaires"
      :loading="loading"
      :headers="headers"
      class="elevation-1"
      :options.sync="options"
      :server-items-length="listMeta.total"
      :footer-props="{ 'items-per-page-options': [10, 20, 50, 100] }"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          :pagination="pagination"
          :options="options"
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          :items-per-page-options="[10, 20, 50, 100]"
        />
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.addedDate }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          small
          block
          depressed
          @click="
            $router.push({
              name: 'questionnaires.reassessment.details',
              params: { id: item.id }
            })
          "
        >
          Edit
        </v-btn>
      </template>
    </v-data-table>

    <AddReassessmentQuestionnaireDialog v-model="addQuestionnaireDialog" />
  </div>
</template>

<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import ControlsMixin from '@/utils/mixins/Controls'
import { mdiPlus } from '@mdi/js'
import { mapState, mapActions, mapMutations } from 'vuex'
import debounce from 'lodash/debounce'
import AddReassessmentQuestionnaireDialog from './components/AddReassessmentQuestionnaireDialog.vue'

export default {
  name: 'PlansPage',
  mixins: [ControlsMixin],
  components: {
    AppBarNavIcon,
    AddReassessmentQuestionnaireDialog
  },
  data() {
    return {
      addQuestionnaireDialog: false,
      icons: {
        add: mdiPlus
      },
      loading: false,
      options: {},
      headers: [
        {
          text: 'Title',
          sortable: true,
          value: 'title'
        },
        {
          text: 'Question Type',
          sortable: true,
          value: 'question_type',
          align: 'center',
          width: '300px'
        },
        {
          text: 'Label',
          sortable: true,
          value: 'label',
          width: '300px'
        },
        {
          text: 'Order',
          sortable: true,
          value: 'order_column',
          width: '150px'
        },
        {
          text: 'Date Created',
          sortable: true,
          value: 'created_at',
          width: '200px'
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          value: 'actions',
          width: '150px'
        }
      ],
      search: null
    }
  },
  computed: {
    ...mapState({
      reassessmentQuestionnaires: state => state.reassessment.list,
      listMeta: state => state.reassessment.listMeta
    })
  },
  methods: {
    ...mapActions({
      getReassessmentQuestionnaires:
        'reassessment/getReassessmentQuestionnaires'
    }),

    ...mapMutations({
      clearReassessmentQuestionnaires:
        'reassessment/clearReassessmentQuestionnaireList'
    }),

    async getDataFromApi() {
      this.loading = true
      this.clearReassessmentQuestionnaires()
      await this.getReassessmentQuestionnaires({
        search: this.search,
        ...this.options
      })
      this.loading = false
    },

    doFilter: debounce(function() {
      this.getDataFromApi()
    }, 600)
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    }
  }
}
</script>
