<template>
  <div>
    <v-dialog v-model="dialog" max-width="600" persistent scrollable>
      <v-form @submit.prevent="addReassessmentQuestionnaire" ref="form">
        <v-card>
          <v-card-title>Add Reassessment Questionnaire</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    required
                    label="Title"
                    hide-details="auto"
                    v-model="form.title"
                    :error-messages="form.$getError('title')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    :items="questionTypes"
                    outlined
                    required
                    label="Question Type"
                    hide-details="auto"
                    v-model="form.question_type"
                    :error-messages="form.$getError('question_type')"
                    item-text="label"
                    item-value="value"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    required
                    label="Label"
                    hide-details="auto"
                    v-model="form.label"
                    :error-messages="form.$getError('label')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <slot>
              <v-btn @click="closeDialog" text>Cancel</v-btn>
              <v-btn type="submit" color="primary" :loading="form.$busy"
                >Submit</v-btn
              >
            </slot>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import Form from '@/utils/form'
import ReassessmentQuestionnaire from '@/models/ReassessmentQuestionnaire'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      dialog: this.value,
      form: new Form({
        title: null,
        question_type: null,
        label: null
      }),
      questionTypes: [
        {
          label: 'Multiple Choice',
          value: 'multiple_choice'
        },
        {
          label: 'Single Choice',
          value: 'single_choice'
        },
        {
          label: 'Identification',
          value: 'identification'
        },
        {
          label: 'Multiple Choice with Open Ended',
          value: 'multiple_choice_open_ended'
        },
        {
          label: 'Time Period',
          value: 'time_period'
        }
      ]
    }
  },

  methods: {
    addReassessmentQuestionnaire() {
      this.form.$busy = true
      this.form.$clearErrors()
      new ReassessmentQuestionnaire(this.form.$data())
        .save()
        .then(res => {
          this.$router.replace(`/questionnaires/reassessment/${res.id}`)
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },

    closeDialog() {
      this.dialog = false
      this.$refs.form.reset()
      this.form.$clearErrors()
    }
  },

  watch: {
    dialog(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.dialog = val
    }
  }
}
</script>
